import React from 'react'
import { Link } from 'react-router-dom'

export default function CompanySignin() {

  return (
    
        
      <section class="bg-auth">
                        <div class="container">
                            <div class="row justify-content-center">
                                <div class="col-xl-10 col-lg-12">
                                    <div class="card auth-box">
                                        <div class="row g-0">
                                            <div class="col-lg-6 text-center">
                                                <div class="card-body p-4">
                                                    <Link to="/">
                                                        <img src="assets/images/nuvo_logo.png" alt="nuvo_logo" height="120" class="logo-light"/>
                                                        <img src="/assets/images/nuvo_logo.png" alt="nuvo_logo" height="120" class="logo-dark"/>
                                                    </Link>
                                                    <div class="mt-2">
                                                        <img src="assets/images/auth/sign-in.png" alt="" class="img-fluid"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="auth-content card-body p-5 h-100 text-white">
                                                    <div class="w-100">
                                                        <div class="text-center mb-4">
                                                            <h5>Welcome Back !</h5>
                                                            <p class="text-white-70">Sign in to continue to Nuvo Hire.</p>
                                                        </div>
                                                        <form action="index.php" class="auth-form">
                                                            <div class="mb-3">
                                                                <label for="usernameInput" class="form-label">Username</label>
                                                                <input type="text" class="form-control" id="usernameInput" placeholder="Enter your username" required/>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="passwordInput" class="form-label">Password</label>
                                                                <input type="password" class="form-control" id="passwordInput" placeholder="Enter your password" required/>
                                                            </div>
                                                            <div class="mb-4">
                                                                <div class="form-check"><input class="form-check-input" type="checkbox" id="flexCheckDefault"/>
                                                                    <a href="reset-password.php" class="float-end text-white">Forgot Password?</a>
                                                                    <label class="form-check-label" for="flexCheckDefault">Remember me</label>
                                                                </div>
                                                            </div>
                                                            <div class="text-center">
                                                                <button type="submit" class="btn btn-white btn-hover w-100">Sign In</button>
                                                            </div>
                                                        </form>
                                                        <div class="mt-4 text-center">
                                                            <p class="mb-0">Don't have an account ? <Link to="/company-signup" class="fw-medium text-white text-decoration-underline"> Sign Up </Link></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

  )
}
